.contact-us {
  max-width: 400px;
  margin: 46px auto;
  width: 100%;
  @include bpm-md {
    margin-top: 15px;
  }
  @include bpm-xs {
    padding: 15px;
  }
  &__heading-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &__sub-heading {
    color: $g-additional-color-14;
    font-weight: 600;
    font-size: 17px;
    margin-bottom: 15px !important;
  }
  &__supporting-text {
    font-weight: normal;
    text-align: center;
    color: $g-additional-color-8;
  }
  &__form-wrapper {
    margin: 0 auto;
    textarea {
      height: 128px;
    }
  }
  &__horizontal-fields {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    .c-form-field {
      content: '';
      border-right: 32px solid transparent;
      flex: 1 0 0;
    }
    .c-form-field:last-child {
      border-right: none;
    }
  }
  &__success-message {
    border-radius: 10px;
    padding: 40px 20px;
    min-height: 100px;
    margin-top: 20px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h3 {
      margin-bottom: 15px;
      margin-top: 15px;
      padding: 0;
    }
    p {
      font-size: 16px;
      margin-bottom: 5px;
    }
  }
  a {
    text-decoration: underline;
  }
}
