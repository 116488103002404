@import './change_password/change_password.style';

.l-profile {
  &__picture-section {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    margin-bottom: 20px;
    .ant-upload-wrapper {
      width: auto;
    }
  }
  &__image-avatar {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    border: 4px solid white;
    margin-bottom: 20px;
    box-shadow:
      0px 4px 6px -2px rgba(16, 24, 40, 0.03),
      0px 12px 16px -4px rgba(16, 24, 40, 0.08);
    &--dummy {
      font-size: 50px !important;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &__form-cancel-btn {
    height: 40px;
    width: 48%;
    margin-right: 2%;

    @include bp-xs {
      margin-right: 10px;
      width: 120px;
    }
  }

  &__form-submit-btn {
    height: 40px;
    width: 48%;
    margin-left: 2%;

    @include bp-xs {
      margin-left: 0;
      width: 120px;
    }
  }

  &__form-delete-btn {
    height: 40px;
    margin-right: auto;
    width: 100%;
    margin-top: 40px;

    @include bp-xs {
      width: auto;
      margin-top: 0;
    }
  }

  &__form-btn-wrapper {
    width: 100%;

    @include bp-xs {
      width: auto;
    }
  }

  &__form-action-btn-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column-reverse;
    margin-top: 10px;

    @include bp-xs {
      flex-direction: row;
      margin-top: 25px;
    }
  }

  &__picture-controllers {
    display: flex;
    align-items: center;
  }
  &__change-photo-btn {
    margin-right: 10px;
  }
  &__dual-fields-wrapper {
    display: flex;
    width: 100%;

    align-items: flex-end;
    justify-content: space-between;

    .c-form-field {
      width: 100%;
      margin-right: 10px;
    }

    .ant-input-password {
      padding: 0px;
    }
    .ant-input-affix-wrapper {
      padding: 0px;
      padding-right: 10px;
      border-left: 0px;
    }
  }
  &__change-pass-btn-wrapper {
    margin-bottom: 10px;
    width: 25%;
  }
  &__change-pass-btn {
    height: 42px;
    background-color: $g-brand-color-9;
    color: $g-brand-color-1;
    font-size: 14px;
    width: 100%;
    padding: 4px 0;
    text-align: center;
  }
}
