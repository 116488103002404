.l-filter-field {
  padding: 0 0 20px;

  &__label {
    margin-bottom: 5px;
    color: $g-dark-color-1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 26px;
  }

  &__input {
    display: flex;
    justify-content: space-between;
  }

  &__select {
    flex: 1 1 100%;
    max-width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: #f7f7fc;
    border-radius: 6px;

    &:first-child {
      margin-right: 10px;
    }

    > p {
      color: #6e7191;
      padding: 0 15px;
      margin: 0;
      font-size: 13px;
    }

    .ant-select {
      width: 100%;
    }

    .ant-select-selector {
      background-color: transparent !important;
      border-color: transparent !important;
      font-size: 13px;
    }
  }
}
