.l-lead-footer {
  color: #86ddd6;
  display: flex;
  background-color: #006464;
  align-items: flex-start;
  padding: 28px 10px;
  margin-top: auto;
  flex-direction: column;
  gap: 18px;

  @include bp-xs {
    padding: 48px 112px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0 !important;
  }
}
