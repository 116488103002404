.l-lead-cta {
  $cta-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  position: fixed;
  z-index: 4;
  bottom: 0;
  right: 0;
  width: 100%;
  background-color: $g-white;
  padding: 6px 8px;
  box-shadow: $cta-shadow;

  @include bp-xs {
    bottom: 50px;
    right: 50px;
    width: auto;
    padding: 0;
    box-shadow: none;
    background-color: transparent;
  }

  &__button {
    height: 56px;
    min-width: 150px;
    background-color: $g-brand-color-18 !important;
    width: 100%;
    box-shadow: none;
    outline: none !important;
    font-weight: 600;

    @include bp-xs {
      width: auto;
      border: 6px solid $g-white;
      box-shadow: $cta-shadow;
    }

    &:hover {
      background-color: $g-additional-color-18 !important;
    }
  }
}
