.l-slider {
  background-color: white;
  margin: 15px 0 0;
  display: flex;
  align-items: center;
  border-radius: 12px;
  justify-content: space-between;
  flex-direction: row;
  padding: 0 16px;
  width: 100%;
  height: 40px;

  @include bp-sm {
    margin: 30px 0 0;
  }

  &__label {
    color: #6e7191;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 14px;
    display: flex;
  }

  &__mark-label {
    color: #6e7191;
    font-weight: normal;
    min-width: 70px;
    font-size: 14px;
    display: block;
    text-align: left;
    margin: 0 0 0 10px;

    &:first-child {
      text-align: right;
      margin: 0 10px 0 0;
    }
  }

  &__active-label {
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    width: 100px;
    display: flex;
    white-space: nowrap;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 422px;
    width: 100%;
  }
}

.l-project-pagination {
  display: flex;
  justify-content: center;
  padding: 15px 0 20px;

  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    border: none;
    border-radius: 5px;
  }

  .ant-pagination-item {
    border: none;
    border-radius: 5px;
    border-color: $g-dark-color-3;
  }

  .ant-pagination-item-active {
    font-weight: bold;
  }
}

.l-project-controls {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
}

.l-project-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.l-project-actions {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 15px;

  &__heading {
    font-size: 18px;
    text-align: center;
  }

  .ant-select {
    margin-bottom: 5px;

    @include bp-sm {
      max-width: 200px;
    }
  }
}

.l-project-details {
  &__name-wrap {
    display: flex;
    border-bottom: 1px solid $g-dark-color-3;
    padding: 0 0 16px;
    margin: 0 8px;
    align-items: center;
    justify-content: flex-start;

    .h-image--table {
      margin-right: 16px;
      min-width: 80px;
      max-width: 80px;
      height: 50px;
    }
  }

  &__name {
    display: flex;
    flex-direction: column;

    h5 {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  &__label {
    text-transform: uppercase;
    color: $g-dark-color-5;
    font-size: 12px;
  }

  &__items {
    list-style: none;
    margin: 0 8px;
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
  }

  &__item {
    display: flex;
    flex-direction: column;
    min-width: 120px;
    margin-right: 32px;
    margin-top: 16px;
  }
}

.l-projects-table {
  .ant-table-tbody > tr > td {
    border-bottom: 3px solid #262338 !important;
  }

  .ant-table-tbody > tr:last-child > td {
    border-bottom: none !important;
  }
}

.l-dashboard-card {
  &--projects {
    min-height: 165px;
  }

  &--projects & {
    &__body {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}
