.h-invite-success-modal {
  &__title-wrapper {
    display: flex;
    align-items: center;

    span {
      display: inline-block;
      line-height: 1;
    }
  }

  &__title-icon-wrapper {
    margin-right: 10px;
  }

  &__text {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    color: $g-additional-color-8;
    margin-bottom: 15px;
  }

  &__btn-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-top: 10px;
  }

  &__btn {
    height: 44px;
    width: 80px;
    border-radius: 8px;
    font-weight: 600;
  }

  &__delete-btn {
    height: 44px;
    width: 140px;
    border-radius: 8px;
    font-weight: 600;
    margin-left: 10px;
  }

  &__emails-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  }

  &__email {
    display: inline-block;
    font-weight: 600;
    font-size: 16px;
    color: $g-dark-color-2;
  }
}
