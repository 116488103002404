.l-enterprise-detail {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;

  &__view {
    width: 90%;
    align-items: center;
    flex-direction: column;
    display: flex;
    padding-top: 30px;

    @include bpm-xs {
      padding-top: 15px;
      width: 100%;
      padding: 15px;
    }
  }

  &__header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
  }

  &__header-icon {
    height: 20px;
    margin-right: 5px;
  }

  &__header-text {
    color: $g-brand-color-1-dark;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    cursor: pointer;
  }

  &__enterprise-info-wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 40px;

    @include bpm-xs {
      margin-top: 40px;
    }
  }

  &__enterprise-picture {
    margin-bottom: 10px;
  }

  &__enterprise-name {
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 10px;
  }

  &__enterprise-address {
    font-size: 18px;
    font-style: normal;
    margin-bottom: 20px;
    text-align: center;
  }

  &__users-section {
    background: $g-white;
    width: 100%;
    border-radius: 8px;
    border: 1px solid #eaecf0;
    box-shadow:
      0px 1px 2px 0px rgba(16, 24, 40, 0.06),
      0px;
    padding-bottom: 10px;
    margin-bottom: 30px;
  }

  &__users-section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
  }
  &__users-section-header-title {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
  }

  &__table-control-btn {
    cursor: pointer;
    display: inline-block;
  }

  &__name-row-wrapper {
    display: flex;
    align-items: center;
    font-weight: 500;
  }

  &__name-row-avatar {
    flex: 1 1 0;
    max-width: 32px;
    min-width: 32px;
  }

  &__name-row-awaiting {
    font-size: 14px;
    color: $g-additional-color-6;
  }

  &__name-row-name {
    color: $g-additional-color-7;
  }

  &__user-status-wrapper {
    padding: 4px 12px;
    border-radius: 15px;
    background: #fffaeb;
    font-weight: 500;
    span {
      display: inline-block;
    }
    &--invited {
      background: #fffaeb;
      color: #b54708;
    }
    &--admin {
      background: #eff8ff;
      color: #175cd3;
      cursor: pointer;
      transition: background 0.3s;

      &:hover {
        background: #f0fcff;
      }
    }
    &--active {
      background: #ecfdf3;
      color: #027a48;
    }
    &--deactivated {
      background: #fef3f2;
      color: #b42318;
    }
    &--user {
      background: #f4f3ff;
      color: #5925dc;
    }
  }

  &__user-status-flex {
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
  }

  &__user-status-dot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 8px;
    &--invited {
      background: #f79009;
    }
    &--admin {
      background: #175cd3;
    }
    &--active {
      background: #12b76a;
    }
    &--deactivated {
      background: #b42318;
    }
    &--user {
      background: #3538cd;
    }
  }

  &__permission-section {
    width: 100%;
  }

  &__permission-section-title {
    font-size: 18px;
    margin-bottom: 10px;

    @include bpm-xs {
      font-size: 20px;
    }
  }
  &__permission-info-wrapper {
    display: flex;
    padding: 16px;
    align-items: flex-start;
    background: $g-white;
    box-shadow:
      0px 1px 2px 0px rgba(16, 24, 40, 0.06),
      0px 1px 3px 0px rgba(16, 24, 40, 0.1);
    border-radius: 8px;
    border: 1px solid #eaecf0;
    margin-bottom: 40px;
  }

  &__permission-info-title {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    color: #344054;
  }
  &__permission-info-description {
    font-size: 14px;
    color: #667085;
  }
}
