@import './modals/agency_modal/agency_modal';
@import './modals/invite_modal/invite_modal.style';
@import './modals/invite_success_modal/invite_success_modal.style';
@import './agency_detail/agency_detail.style';
@import './modals/delete_agency_user_modal/delete_agency_user_modal.style';

.l-agency {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;

  &__view {
    width: 80%;
    align-items: center;
    flex-direction: column;
    display: flex;
    padding-top: 8%;

    @include bpm-xs {
      width: 100%;
      padding: 15px 5px !important;
    }
  }

  &__enterprise-picture {
    margin-bottom: 30px;
    width: 100%;
    padding: 0 7px;
    display: flex;

    @include bpm-xs {
      justify-content: center;
      align-items: center;
      margin-bottom: 15px;
    }
  }

  &__search-field-wrapper {
    width: 100%;
    padding: 0 5px;

    .ant-input-prefix {
      margin-inline-end: 15px;
    }

    .ant-input-suffix {
      margin-inline-start: 15px;
      margin-inline-end: 10px;
      cursor: pointer;
    }
  }

  &__search-input {
    width: 100% !important;
    margin-bottom: 10px;
    height: 64px;
    border-radius: 16px;
    input {
      height: auto !important;
    }
  }

  &__search-results-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    // max-width: 1000px;
    margin-top: 30px;
    padding-bottom: 30px;
    width: 100%;

    @include bpm-xs {
      margin-top: 5px;
      padding-bottom: 15px;
    }

    .ant-input-suffix {
      display: block;
      line-height: 1;
    }
  }

  &__card {
    display: flex;
    align-items: flex-start;
    background: #fff;
    border-radius: 16px;
    box-shadow: 2px 4px 6px 0px rgba(0, 0, 0, 0.04);
    width: 100%;
    height: 160px;
    cursor: pointer;
  }

  &__card-icon {
    min-width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $g-brand-color-1;
    height: 100%;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    flex: 0 0 auto;

    @include bpm-sm {
      min-width: 90px;
    }
    &--create {
      background: #ebeceb;
    }

    svg {
      @include bpm-xs {
        height: 64px;
      }
    }
  }
  &__card-content {
    padding: 20px;
    display: flex;
    width: 100%;
    flex: 1;

    &--create {
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
    }
  }
  &__card-entrprise-name {
    font-weight: 400;
    font-size: 13px;
  }
  &__card-agency-name {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @include bpm-sm {
      font-size: 16px;
    }
  }
  &__card-agency-address {
    font-size: 15px;
    font-weight: 400;
    color: $g-additional-color-11;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  &__card-content-wrapper {
    margin-right: 12px;
    width: 100%;
  }
  &__card-control-item {
    font-size: 16px;
    display: inline-block;
    width: 100%;
  }

  &__card-control-btn {
    height: 40px;
    width: 40px;
    padding: 0px;
    background: #eff0f6;
    font-weight: 700;
    outline: none;
    border-color: transparent;
    color: $g-additional-color-11;
    font-size: 20px;
    display: flex;
    justify-content: center;
    &:hover,
    &:focus {
      outline: none;
      color: $g-additional-color-11 !important;
    }
  }
}
