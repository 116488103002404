$g-font-pgp: 'Poppins', sans-serif;

.l-portfolio-growth-plan {
  background-color: $g-white;
  border-top: 1px solid $g-brand-color-4;
  height: 100%;
  width: 100%;
  font-family: $g-font-pgp;
  min-height: calc(100vh - 70px);

  @include bp-sm {
    min-height: 90vh;
  }

  &__typeform {
    display: flex;
    height: 100%;
    width: 95%;
    margin: 0 auto;

    @include bp-sm {
      width: 100%;
    }

    iframe {
      display: block;
    }
  }

  &__timer {
    width: 100%;
    max-width: 560px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 50px 20px;
    position: relative;
  }

  &__logo {
    max-width: 66px;
    max-height: 66px;
    width: 100%;
    position: absolute;
    top: 32px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__heading {
    font-family: $g-font-pgp;
    font-weight: 400;
    color: $g-black;
    text-align: center;
    font-size: 24px;

    @include bp-md {
      font-size: 36px;
    }
  }

  &__timer-display {
    font-family: $g-font-pgp;
    font-weight: 300;
    color: $g-black;
    margin: 10px 0;
    font-size: 80px;
    // color: rgb(85, 189, 126);
  }

  &__brand {
    background: linear-gradient(45deg, #00b2a3, #55bd7e);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
    font-weight: 500;
  }

  &__text {
    text-align: center;
    max-width: 320px;
    font-family: $g-font-pgp;
  }

  &__start-text {
    text-align: center;
    max-width: 500px;
    font-size: 16px;
    font-family: $g-font-pgp;

    &:not(:last-child) {
      margin: 0 0 13px;
    }

    strong {
      padding-top: 30px;
      display: block;
    }
  }

  &__enter-button {
    display: flex;
    justify-content: center;
    flex-direction: column;

    button {
      position: relative;
      font-weight: 700;
      cursor: pointer;
      outline: none;
      border: 1px solid transparent;
      margin: 0px;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 12px 0px;
      padding-block: 8px;
      padding-inline: 24px;
      min-height: 48px;
      background-color: rgb(85, 189, 126);
      color: rgb(255, 255, 255);
      border-radius: 5px;
      font-family: $g-font-pgp;
      text-transform: uppercase !important;
      font-size: 20px;
      display: block;
    }
  }

  &__message {
    width: 100%;
    max-width: 720px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    position: relative;
  }

  &__modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  &__modal-text {
    font-family: $g-font-pgp;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 10px;
  }

  &__modal-load-text {
    font-family: $g-font-pgp;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 0 !important;
  }

  .ant-result-title {
    font-family: $g-font-pgp;
    line-height: 27px;
    font-size: 20px;
  }
}
