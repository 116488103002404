.l-learn-more {
  display: flex;
  flex-direction: column;
  padding: 15px 16px 0;

  button {
    font-size: 14px;
    cursor: pointer;
    text-align: left;
  }

  > button {
    width: 100%;
    margin-bottom: 8px;
  }

  svg {
    opacity: 1;
    transition: opacity 0.3s ease-in-out;

    &:hover {
      opacity: 0.8;
    }
  }

  > div {
    width: 100%;

    p:first-child {
      padding-bottom: 2px;
      margin-bottom: 0;
      font-weight: 500;
    }

    p:last-child {
      opacity: 0.8;
    }
  }

  &__video-btn {
    img {
      border-radius: 6px;
      opacity: 1;
      transition: opacity 0.3s ease-in-out;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  &--mobile {
    flex-direction: row;
    padding: 0 0 15px;
    gap: 10px;
    align-items: flex-start;
    justify-content: flex-start;

    > div {
      flex: 3 3 100%;
    }

    > button {
      flex: 1 0 85px;
      margin-bottom: 0;
    }
  }
}

.thin-padding-modal {
  .ant-modal-content .ant-modal-body {
    padding: 2px 12px 10px !important;
  }
}
