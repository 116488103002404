.pgp-menu-trigger {
  width: 100%;
  height: 48px;
  border-radius: 8px;
  border: 1px solid $g-additional-color-13;
  box-shadow: 0px 2px 4px -2px #1018280f;
  background: #f8fffe;
  font-weight: 600;
  color: $g-additional-color-7;
  font-size: 16px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__arrow-down {
    color: $g-additional-color-8;
  }
  &__info-wrapper {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    svg {
      margin-right: 10px;
      color: $g-additional-color-14;
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__info-name {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  &__icon-wrapper {
    margin-right: 10px;
  }
}

.pgp-mobile-menu {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: white;
  z-index: 6;
  transition: transform 0.3s ease-in-out;
  // Initially position the modal offscreen at the bottom
  transform: translateY(100%);
  &--visible {
    visibility: visible;
    height: 100%;
    // Slide the modal up into view
    transform: translateY(0);
  }
  &--hide {
    visibility: hidden;
    height: 0px;
    transform: translateY(100%);
  }
  svg {
    color: $g-additional-color-14;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 16px;
    border-bottom: 1px solid #f2f4f7;
    font-size: 24px;
    color: $g-additional-color-8;
  }
  .pgp-menu-item {
    font-size: 16px;
    font-weight: 600;
    color: $g-additional-color-7;
    height: 48px;
    padding-inline: 16px !important;
    background-color: transparent !important;
  }
  .pgp-sub-menu {
    font-size: 16px;
    font-weight: 600;
    color: $g-additional-color-7;

    &--property-card {
      border: 1px solid $g-additional-color-13;
      box-shadow: 0px 5px 10px 0px #10182814;
      margin: 10px 5px;
      .ant-menu-submenu-title {
        // border: 1px solid $g-additional-color-13;
        height: 80px !important;

        padding-right: 32px !important;
      }
    }
  }

  // Ant Design overrides
  .ant-menu-submenu-title {
    height: 48px !important;
    padding-inline: 16px !important;
    background-color: transparent !important;
  }
  .ant-menu-item-icon {
    height: 24px;
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      color: $g-additional-color-14;
      font-size: 20px;
      height: 24px;
      width: 24px;
    }
  }
  .ant-menu-title-content {
    margin-inline-start: 14px;
    color: $g-additional-color-7;
  }
  .ant-menu {
    padding: 5px;
    background: transparent !important;
  }
  .ant-menu-sub {
    background: none;
  }
}
