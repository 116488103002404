.l-video-explainer {
  position: fixed;
  transition: transform 0.1s;
  transform-origin: center center;
  z-index: 10;

  height: 180px;
  width: 180px;

  &__player {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    border-radius: 50%;
    overflow: hidden;

    height: 180px;
    width: 180px;

    .video {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 270px;
      height: 400px;
    }
  }

  &__pointer {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    transition: transform 0.1s ease;
    height: 180px;
    width: 180px;
  }

  &__pointer-arrow {
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid #a5b6bb;
    position: absolute;
    top: -17px;
    left: 50%;
    margin-left: -20px;
    z-index: 2;
  }

  &__pointer-body {
    z-index: 2;
    height: 182px;
    width: 182px;
    border-radius: 50%;
    background-color: transparent;
    border: 6px solid #a5b6bb;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__play-button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    background-color: $g-white;
    border-radius: 50%;
    z-index: 4;

    left: 10px;
    top: 58px;
    width: 32px;
    height: 32px;

    @include bp-md {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 45px;
      height: 45px;
    }
  }

  &__play-button--hidden {
    @include bp-md {
      opacity: 0;
      visibility: hidden;
      transition:
        opacity 0.3s ease,
        visibility 0.3s ease;

      &:hover {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &__play-button-icon {
    color: $g-text-base;
    display: flex;
    margin-left: 3px;

    width: 13px;
    height: 15px;

    @include bp-md {
      width: 18px;
      height: 20px;
    }
  }

  &__pause-button-icon {
    color: $g-text-base;
    display: flex;

    width: 14px;
    height: 14px;

    @include bp-md {
      width: 18px;
      height: 18px;
    }
  }

  &__close-button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    background-color: $g-white;
    border-radius: 50%;
    z-index: 4;
    transition:
      opacity 0.3s ease,
      visibility 0.3s ease;

    width: 22px;
    height: 22px;
    bottom: 58px;
    left: 15px;

    @include bp-md {
      opacity: 0;
      visibility: hidden;
      bottom: 10px;
      left: 50%;
      width: 26px;
      height: 26px;
      transform: translateX(-50%);
    }
  }

  &__close-button-icon {
    color: $g-text-base;
    font-size: 14px;
  }

  &__spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 180px;
    width: 180px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    background-color: #000;
    border-radius: 50%;

    .ant-spin-dot {
      color: $g-white;
    }
  }

  &:hover {
    @include bp-md {
      .l-video-explainer__play-button--hidden {
        opacity: 0.7;
        visibility: visible;
      }

      .l-video-explainer__close-button {
        opacity: 0.7;
        visibility: visible;
      }
    }
  }
}

.l-video-explainer-trigger {
  $color-secondary: #a5bbbb;
  $color-hover: #bbd0d0;
  $color-text: #8fa7a7;

  position: relative;

  &__button {
    position: absolute;
    top: -13px;
    right: -13px;
    z-index: 1;
    cursor: pointer;
    display: block;
    background: $g-additional-color-17;
    height: 26px;
    width: 26px;
    border-radius: 50%;
    color: $color-text;
    font-size: 14px;
    border: 2px solid $color-secondary;
    font-weight: 500;

    &--active {
      background: $color-secondary !important;
      color: $g-additional-color-17 !important;
      height: 30px;
      width: 30px;
      top: -15px;
      right: -15px;
    }

    &:hover {
      background: $color-hover;
      color: $g-additional-color-17;
    }
  }

  &__overlay {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    background: $g-white;
    opacity: 0.8;
    z-index: 1;
  }
}

.l-learn-more-video {
  position: relative;
  width: 100%;
  height: 100%;
}
