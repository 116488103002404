.c-app-layout {
  &__header-logo {
    display: flex;
    align-items: center;

    @include bpm-sm {
      margin-right: 10px;
    }
    @media (max-width: 860px) {
      margin-right: 10px;
    }
  }
  &__header-home-navigator {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    color: $g-additional-color-8;
    margin-right: 40px;
    cursor: pointer;
  }
  &__header-left-side {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    padding-left: 50px;
    height: 100%;
    max-height: 65px;
    @include bpm-md {
      padding-left: 10px;
    }
  }
  &__header-right-side {
    padding-right: 50px;
    display: flex;
    justify-content: space-between;
    flex: 1 1 0;
    @include bpm-md {
      padding-right: 10px;
    }
    @media (max-width: 860px) {
      padding-right: 0;
      flex-direction: column-reverse;
      align-items: flex-end;
      width: 100%;
    }
    @include bpm-sm {
      padding-right: 0;
      flex-direction: column-reverse;
      align-items: flex-end;
      width: 100%;
    }
  }
  &__header-user-avatar {
    border-radius: 50%;
    border: 4px solid #d6f6f4;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__header-details-wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;
    @media (max-width: 860px) {
      padding: 0 0 10px;
    }
    @include bpm-sm {
      padding: 0 0 10px;
    }
  }
  &__header-details {
    margin-right: 10px;
    @media (max-width: 860px) {
      margin-right: 0;
      justify-content: space-between;
      padding: 0 10px;
    }
    @include bpm-sm {
      margin-right: 0;
      justify-content: space-between;
      padding: 0 10px;
    }
  }
  &__header-portfolio-type {
    border-radius: 8px;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0px 20px;
    background-color: #daf2ec;
    color: $g-brand-color-1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 310px;
    width: 100%;

    @include bpm-xl {
      max-width: 300px;
    }

    @media (max-width: 1320px) {
      max-width: 140px;
    }

    @include bpm-lg {
      max-width: 145px;
    }
    @media (max-width: 860px) {
      flex: 1 1 0;
      max-width: unset;
    }
    @include bpm-sm {
      flex: 1 1 0;
      max-width: unset;
    }
  }
  &__header-portfolio-type-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__header-user-dd {
    .ant-dropdown-menu-item {
      border-radius: 0px !important;
      font-weight: 500 !important;
    }
    li:not(:last-child) {
      border-bottom: 1px solid #f2f4f7 !important;
    }
    li:has(.c-app-layout__profile-dd-info-wrapper) {
      padding-left: 10px !important;
    }
  }
  &__header-user {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 65px;
    @include bpm-sm {
      margin-right: 10px;
    }
  }
  &__header-all-details-wrapper {
    margin-right: 10px;
    margin-left: 10px;

    display: flex;

    @media (max-width: 860px) {
      flex-direction: column;
      margin-right: 0;
      width: 100%;
      padding: 0 10px;
      line-height: 45px;
    }
    @include bpm-sm {
      flex-direction: column;
      margin-right: 0;
      width: 100%;
      padding: 0 10px;
      line-height: 45px;
    }
  }
  &__header-nav {
    display: flex;
  }

  &__header-nav-item {
    font-weight: 600;
    color: #667085;
    padding: 0 5px;
    text-align: left;
    line-height: 1.5em;
    margin-left: 35px;
    font-size: 15px;
    max-width: none;

    &:hover {
      color: #00c1b1;
      text-decoration: none;
    }

    &:first-child {
      margin-left: 0;
    }

    &--active {
      color: #009387;
      position: relative;

      &:before {
        content: '';
        width: 100%;
        height: 2px;
        background-color: #009387;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
  }

  &__header-nav-small {
    height: 65px;
    margin-right: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      margin-left: 5px;
      border-radius: 50%;
      border: 4px solid #d6f6f4;
      height: 40px;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &__header-client {
    display: flex;
    align-items: center;
    cursor: pointer;
    border: 1px solid $g-dark-color-7;
    height: 45px;
    margin: 10px;
    padding: 5px 10px;
    max-width: 200px;
    width: 200px;
    font-weight: 600;
    color: $g-dark-color-3;
    justify-content: space-between;
    margin-right: 20px;
    border-radius: 8px;
    @include bpm-sm {
      margin: 10px 0 9px;
      width: 100%;
      max-width: unset;
    }
  }
  &__header-client-name-wrapper {
    width: 140px;
    margin-right: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    @include bpm-sm {
      width: 100%;
    }
  }
  &__header-client-image-wrapper {
    margin-right: 10px;
    display: flex;
  }
  &__header-user-name {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    margin-right: 10px;
    color: $g-dark-color-2;
    white-space: nowrap;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (max-width: 1550px) {
      display: none;
    }

    @include bpm-xl {
      display: none;
    }
  }
  &__header-client-details {
    display: flex;
    align-items: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    @include bpm-sm {
      width: 100%;
    }
  }
  &__profile-dd-info-wrapper {
    display: flex;
    align-items: center;

    .ant-badge-dot {
      right: 4px;
      bottom: 0px;
      top: 82%;
      width: 8px;
      min-width: 8px;
      height: 8px;
    }
  }
  &__profile-dd-info-avatar {
    margin-right: 12px;
  }
  &__profile-dd-info-text-name {
    font-weight: 600;
    font-size: 14px;
    color: $g-dark-color-2;
  }
  &__profile-dd-info-text-email {
    color: $g-additional-color-8;
    font-size: 14px;
  }

  &__header-mobile-drawer-close-btn {
    color: $g-additional-color-8;
  }

  &__header-mobile-drawer-menu-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 8px 16px 8px 16px;
    height: auto;
    margin-bottom: 5px;
    &--info {
      margin-top: 1rem;
      margin-bottom: 1rem;
      padding-left: 12px;
      height: auto;
    }
  }

  &__header-mobile-drawer-menu-item-icon {
    margin-right: 1rem;
    svg {
      height: 24px;
      width: 24px;
      color: $g-additional-color-14;
    }
  }

  &__header-mobile-drawer-menu-item-label {
    font-family: $g-font-base;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
  }

  &__header-mobile-drawer-bottom-menu-item-wrapper {
    display: flex;
    align-items: center;
    padding: 12px 16px 12px 16px;
    justify-content: center;
  }

  &__header-mobile-drawer-bottom-menu-item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    width: 100%;
    margin-bottom: 1rem;
  }

  &__header-mobile-drawer-menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .h-color--dark {
    @include bpm-lg {
      background-color: $g-dark-color-2;
    }
  }
}

.l-typeform {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
