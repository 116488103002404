@import './suburb_map/suburb_map.style';
@import './suburb_details/suburb_details.style';

.l-report {
  background-color: $g-white;

  &__back {
    margin-right: auto;
  }

  &__share,
  &__favorite {
    margin-left: auto;
  }

  &__heading {
    display: flex;
    align-items: center;
    margin-bottom: 35px;
    flex-direction: column;
    justify-content: center;

    @include bpm-md {
      margin-bottom: 20px;
    }

    h4 {
      margin-top: 4px;
      font-size: 24px;
      color: black;
      padding-bottom: 0;
      line-height: 1.1;

      @include bpm-md {
        font-size: 20px;
        font-weight: normal;
      }
    }
  }

  &__tag {
    margin-bottom: 22px;
    text-transform: capitalize;
    padding: 4px 10px;
    background-color: $g-brand-color-1;
    border-radius: 6px;
    color: $g-white;
  }

  &__button-group-wrap {
    position: relative;
    margin-right: 11px;
    padding-right: 12px;

    &--border {
      &:after {
        position: absolute;
        content: '';
        right: 0;
        top: 0;
        height: 50%;
        margin-top: 6.5%;
        width: 1px;
        background-color: #ccc;

        @include bp-md {
          margin-top: 4%;
        }
      }
    }

    .ant-radio-group {
      display: flex;
    }

    .ant-radio-button-wrapper {
      width: inherit;
    }
  }

  &__client-view {
    margin-top: 0;
    padding: 0;

    .client-view__search-results-wrapper {
      margin: 0;
    }

    .client-view__card-avatar {
      margin-bottom: 0;
      margin-right: 12px;
    }
  }

  &__client-view-list {
    max-height: 500px;
    overflow-y: auto;
    width: 100%;
    margin: 5px 0;
  }

  &__client-view-button {
    width: 130px;
    height: 42px;
    margin: 30px 5px 0;
  }

  &__client-view-list-item {
    list-style: none;
    margin-bottom: 10px;
    width: 100%;

    a {
      padding: 10px;
      border-radius: 7px;
      background-color: #f7f8f9;
      border: 1px solid #f7f8f9;
      color: inherit;

      display: flex;
      align-items: center;
      width: 100%;

      &:hover {
        text-decoration: none;
        border: 1px solid #aaa;
        background-color: #fafbfc;
      }
    }

    &--selected {
      a {
        text-decoration: none;
        border: 1px solid $g-brand-color-1 !important;
        background-color: #fafbfc;
        color: $g-brand-color-1;
      }
    }
  }
}

.l-report-main {
  padding-top: 40px;

  @include bpm-md {
    padding-top: 5px;
  }

  &__content {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10px;

    @include bp-xl {
      width: 1140px;
      padding: 0;
    }

    h3 {
      font-size: 32px;
      color: black;
      margin-bottom: 0;
      padding-bottom: 10px;
    }
  }

  &__actions {
    width: 100%;
    display: flex;
    justify-content: space-between;
    min-height: 30px;
    align-items: center;
    margin-bottom: 30px;

    @include bpm-md {
      margin-bottom: 15px;
    }
  }
}

.l-report-details {
  background-color: #f0f3f6;
  min-height: 100px;
  padding-bottom: 50px;
  position: relative;

  @include bp-sm {
    padding-bottom: 75px;
  }

  @include bpm-xxl {
    padding-bottom: 100px;
  }

  &::before {
    content: '';
    position: absolute;
    top: -2px;
    left: 0;
    width: 100%;
    height: 76px;
    background-color: $g-white;
  }

  &__content {
    position: relative;
    min-height: 100px;
    width: 100%;
    margin: 0 auto;
    padding: 0 10px;

    @include bp-xl {
      width: 1140px;
      padding: 0;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: $g-brand-color-1;
    }

    .ant-tabs-ink-bar {
      background-color: $g-brand-color-1;
    }

    .ant-tabs-top > .ant-tabs-nav::before {
      display: none;
    }

    .ant-tabs-top > .ant-tabs-nav {
      margin-bottom: 20px;

      @include bp-sm {
        margin-bottom: 50px;
      }
    }

    .ant-tabs-tab {
      justify-content: center;
      font-weight: bold;
      font-size: 15px;
      padding: 15px 0;
      text-align: center;
      align-items: flex-end;

      @include bp-xl {
        width: 160px;
      }
    }

    .ant-tabs .ant-tabs-tab .anticon {
      margin-right: 0 !important;
    }
  }

  .l-project-actions {
    &--report-margin {
      margin-left: auto;
    }

    &--report-width {
      max-width: 150px;
    }
  }
}
