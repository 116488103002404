.h-enterprise-modal {
  &__picture-section {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    margin-bottom: 20px;
    margin-top: 20px;
    .ant-upload-wrapper {
      width: auto;
    }
  }
  &__image-avatar {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    border: 4px solid white;
    margin-bottom: 20px;
    box-shadow:
      0px 4px 6px -2px rgba(16, 24, 40, 0.03),
      0px 12px 16px -4px rgba(16, 24, 40, 0.08);
    &--dummy {
      font-size: 50px !important;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &__form-cancel-btn {
    margin-right: 20px;
    height: 40px;
  }

  &__form-submit-btn {
    width: 150px;
    height: 40px;
  }
  &__change-photo-btn {
    margin-right: 10px;
    width: 100% !important;
    height: 100%;
  }
  &__dual-fields-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .c-select .ant-select-selector {
      height: 40px !important;
    }

    .c-form-field {
      width: 45%;
    }

    .ant-input-password {
      padding: 0px;
    }
    .ant-input-affix-wrapper {
      padding: 0px;
      padding-right: 10px;
      border-left: 0px;
    }
  }

  &__picture-controllers {
    display: flex;
    align-items: center;
    width: 100%;
    background: #eff0f6;
    border-radius: 8px;
    padding: 20px;
    height: 200px;
    cursor: pointer;
    .ant-upload {
      width: 100%;
      height: 100%;
    }
  }
  &__picture-uploader {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  &__picture-uploader-text {
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
  }
  &__form-btn-wrapper {
    @include bpm-xs {
      justify-content: center;
    }
  }
}
