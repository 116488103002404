.l-lead-book-a-call {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__iframe {
    display: block;
    width: 100%;
    height: calc(100vh - 120px);

    @include bp-sm {
      height: calc(100vh - 65px);
    }
  }
}
