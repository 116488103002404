@import './header/header.style';
@import './pgp_menu/pgp_menu.style';

.c-app-layout {
  height: 100% !important;
  min-height: 100%;
  overflow-y: scroll;
  .ant-layout {
    @include bpm-mmd {
      background: $g-additional-color-15;
    }
  }
  @include bpm-mmd {
    background: $g-additional-color-15;
  }
  &__header {
    background-color: $g-white;
    height: 65px;
    padding: 0 0 0 280px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    @include bpm-md {
      padding: 0 0 0 190px;
    }
    @media (max-width: 860px) {
      height: unset;
      padding: 0;
    }
    @include bpm-sm {
      height: unset;
      padding: 0;
    }
  }
  &__sider {
    position: relative;

    .pgp-sub-menu {
      svg {
        height: 14px;
        width: 14px;
        + span {
          margin-inline-start: 10px;
        }
      }
    }
    .pgp-menu-item {
      svg {
        height: 14px;
        width: 14px;
        + span {
          margin-inline-start: 10px;
        }
      }
    }
    @include bpm-lg {
      z-index: 1000;
      position: absolute !important;
      height: 100%;
      top: 0;
      left: 0;
    }

    .ant-layout-sider-zero-width-trigger {
      @include bpm-sm {
        top: 10px;
        inset-inline-end: -30px;
        z-index: 1;
        width: 30px;
        height: 30px;
      }
    }
  }
  &__sold-indicator {
    width: 20px;
    background: $g-error;
    color: $g-white;
    border-radius: 50px;
    font-size: 14px;
    height: 20px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
  }
  &__content {
    border-radius: 2px;
    background-color: $g-white;
    margin-left: 25px;
    padding: 30px;
    overflow-y: scroll;
    position: relative;
    @include bpm-lg {
      margin-left: 0;
    }
    @include bpm-mmd {
      background-color: $g-additional-color-15;
    }

    @include bpm-sm {
      padding: 20px;
    }
  }
}

.l-content-header {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  &__title {
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 5px;
    color: $g-title-color;
  }
  &__subtitle {
    font-size: 16px;
    color: $g-sub-title-color;
  }
}
