$text-color: #181d27;

.c-home-page {
  .ant-divider {
    margin: 0px;
  }
  &__header {
    background-color: $g-white;
    height: 80px;
    position: sticky;
    top: 0;
    z-index: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5%;
    @include bpm-sm {
      height: 80px;
      padding: 10px;
    }

    @media (min-width: 1600px) {
      height: 100px; /* Increase height for large screens */
    }

    @include bp-xxxl {
      height: 140px;
    }
  }

  &__header-logo {
    display: flex;
    align-items: center;
    svg {
      @include bp-xxxl {
        width: 300px;
        height: 85px;
      }
      @include bpm-sm {
        width: 160px;
        height: 60px;
      }
    }
  }

  &__header-actions {
    display: flex;
    align-items: center;
    gap: 20px; /* Consistent spacing between buttons */
    @include bpm-sm {
      gap: 5px;
    }
  }

  &__header-action-btn {
    height: 44px;
    font-family: $g-additional-font;
    font-weight: 600;
    font-size: 16px;
    padding: 12px 24px;
    &--login {
      color: $g-additional-color-16;
      margin-right: 1rem;
    }

    &--signup {
      box-shadow: 0px 1px 2px 0px #0a0d120d;
      box-shadow: 0px -2px 0px 0px #0a0d120d inset;
      box-shadow: 0px 0px 0px 1px #0a0d122e inset;
      border: 2px;
      background: #019386;
    }

    @media (min-width: 1600px) {
      font-size: 18px;
      padding: 14px 28px;
    }

    @include bp-xxxl {
      font-size: 26px;
      height: 80px;
      padding: 14px 40px;
    }

    @include bpm-xs {
      padding: 12px 15px;
      height: 44px;
      font-size: 16px;
    }

    @include bpm-xxs {
      padding: 5px 10px;
      height: 36px;
      font-size: 14px;
    }
  }

  &__section {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 5rem 0px;
    background-color: rgba(250, 250, 250, 1);
    font-family: $g-additional-font;
    justify-content: space-between;
    &--first {
      @include bpm-sm {
        padding-bottom: 0px;
      }
    }
    &--small {
      flex-direction: column;
      justify-content: flex-start;
      padding: 5rem 64px;
      @include bpm-sm {
        align-items: center;
        padding: 2rem;
      }
    }
    &--centralized {
      justify-content: flex-start;
      flex-direction: column;
      align-items: center;
    }
    &--no-padding {
      padding: 5rem 0px;
      @include bpm-sm {
        padding: 0px;
        padding-top: 5rem;
      }
    }

    @include bpm-sm {
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  &__section-content {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
    justify-content: center;
    padding-left: 64px;
    padding-right: 40px;
    &--first {
      width: 50%;
    }
    @include bpm-sm {
      width: 100%;
      margin-bottom: 4rem;
    }
    @include bpm-xs {
      padding: 0px 20px;
    }
  }

  &__section-img-mobile {
    display: none;

    @include bpm-sm {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 70%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  &__section-img {
    flex: 1;
    display: flex;
    justify-content: flex-end;

    @include bpm-sm {
      display: none;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    &--first {
      img {
        width: 80%;
      }
    }
  }

  &__get-free-btn {
    background: rgba(1, 147, 134, 1);
    height: 60px;
    padding: 16px 22px;
    font-weight: 600;
    font-family: $g-additional-font;
    font-size: 18px;
    line-height: 28px;
    max-width: 200px;
    @include bp-xxxl {
      height: 80px;
      max-width: 300px;
      font-size: 28px;
      line-height: 38px;
    }
    @include bpm-sm {
      width: 100%;
      max-width: 100%;
    }
  }

  &__main-content-sml-heading {
    font-size: 20px;
    font-weight: 600;
    color: rgba(0, 118, 108, 1);
    margin-bottom: 1.5rem;

    @include bp-xxxl {
      font-size: 35px;
    }
  }

  &__main-content-main-heading {
    font-family: $g-additional-font-1;
    font-weight: 600;
    font-size: 60px;
    color: #181d27;
    line-height: 72px;
    letter-spacing: -0.02em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin-bottom: 3rem;
    @include bp-xxxl {
      font-size: 80px;
      line-height: 100px;
    }
    @include bpm-xs {
      font-size: 36px;
      line-height: 44px;
    }
  }

  &__section-heading {
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    font-family: $g-additional-font-1;
    color: $text-color;
    margin-bottom: 2rem;
    letter-spacing: -0.02em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    &--col {
      display: flex;
      @include bpm-mmd {
        flex-direction: column;
      }
      span:first-of-type {
        margin-right: 10px;
        @include bpm-mmd {
          margin-right: 0px;
        }
      }
    }
    @include bp-xxxl {
      font-size: 50px;
      margin-bottom: 3rem;
    }
    @include bpm-sm {
      font-size: 30px;
      line-height: 38px;
      text-align: center;
    }
  }

  &__section-sub-heading {
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
    font-family: $g-additional-font-1;
    color: $text-color;
    margin-bottom: 2rem;
    @include bp-xxxl {
      font-size: 40px;
      line-height: 48px;
    }
    @include bpm-sm {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__section-description {
    font-size: 18px;
    font-family: $g-additional-font;
    font-weight: 400;
    line-height: 28px;
    color: $g-dark-color-10;
    margin-bottom: 2rem;
    @include bp-xxxl {
      font-size: 34px;
      line-height: 48px;
    }
    &--sub-heading {
      font-size: 20px;
      max-width: 50%;
      text-align: center;
      @include bp-xxxl {
        font-size: 30px;
      }
      @include bpm-sm {
        max-width: 90%;
      }
    }
  }

  &__confidence-section-content {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 48px;
    max-width: 1400px;
    margin: 0 auto;
    text-align: center;
    @include bp-xxxl {
      max-width: 2000px;
    }
    @include bpm-sm {
      grid-template-columns: repeat(2, 2fr);
    }
    @include bpm-xs {
      width: 100%;
      max-width: 100%;
      display: flex;
      margin: 0;
      flex-direction: column;
      align-items: center;
    }
  }

  &__confidence-section-item {
    max-width: 280px;
    @include bp-xxxl {
      max-width: 400px;
    }
    @include bpm-sm {
      min-width: 250px;
    }
    @include bpm-xs {
      width: 100%;
      max-width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0;
      text-align: center;
    }
  }

  &__confidence-section-item-icon {
    border: 1px solid #e9eaeb;
    width: 48px;
    height: 48px;
    gap: 0px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
    @include bp-xxxl {
      width: 80px;
      height: 80px;
      svg {
        width: 40px;
        height: 40px;
      }
    }
  }

  &__confidence-section-item-content {
    font-family: $g-additional-font;
    font-size: 20px;
    font-weight: 600;
    line-height: 40px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    @include bp-xxxl {
      font-size: 30px;
    }
    @include bpm-xs {
      text-align: center;
    }
  }

  &__how-it-works-points-container {
    margin-bottom: 2rem;
  }

  &__how-it-works-point {
    display: flex;
    span {
      display: inline-block;
      margin-bottom: 1rem;
    }

    svg {
      color: #019386;
      font-size: 24px;
      margin-top: 3px;
      margin-right: 10px;
      @include bp-xxxl {
        font-size: 40px;
      }
    }
  }

  &__award-section-content {
    display: grid;
    grid-template-columns: repeat(5, 1fr); /* 5 awards in a row */
    gap: 40px; /* Consistent spacing */
    max-width: 1400px; /* Prevent ultra-wide stretching */
    margin: 0 auto; /* Center the content */
    align-items: flex-start;
    @include bp-xxxl {
      max-width: 2200px;
      gap: 80px;
    }
    @include bpm-sm {
      grid-template-columns: repeat(2, 2fr);
    }
    @include bpm-xs {
      width: 100%;
      max-width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0;
    }
  }

  &__award-section-item-content {
    font-family: $g-additional-font;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: $g-dark-color-10;
    @include bp-xxxl {
      font-size: 26px;
      line-height: 40px;
    }
  }

  &__award-section-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: 220px;
    @include bp-xxxl {
      max-width: 400px;
    }
    img {
      display: inline-block;
      width: 160px; /* Ensure uniform image size */
      height: 64px;
      object-fit: contain; /* Prevent distortion */
      @include bp-xxxl {
        width: 300px;
        height: 120px;
      }
      @include bpm-xs {
        width: 247px; /* Ensure uniform image size */
        height: 88px;
      }
    }
  }

  &__award-section-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 0;
    width: 100%;
  }
}
