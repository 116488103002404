.client-dash-login {
  display: flex;
  width: 100%;
  height: 100%;

  .c-form {
    &__btn-wrapper {
      margin-top: 15px;
    }
  }

  &__google-button {
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $g-font-base;
    margin-bottom: 1rem;

    svg {
      margin-right: 5px;
    }
  }

  .c-form-field {
    margin-bottom: 10px;
    &__error {
      font-size: 12px;
    }
  }

  .c-form-field__wrapper {
    margin-bottom: 6px;
  }
  &__logo-container {
    width: 100%;
    justify-content: flex-start;
    display: flex;
    padding-top: 15px;
    cursor: pointer;
    @include bpm-sm {
      margin-bottom: 4rem;
    }
  }

  &__link-ref {
    display: flex;
    justify-content: center;
    @include bpm-sm {
      margin-bottom: 2rem;
    }
  }

  &__link {
    cursor: pointer;
    margin-left: 10px;
    white-space: nowrap;
  }

  &__footer-email-link {
    cursor: pointer;
    color: $g-additional-color-8;
    display: inline-block;
  }
  &__image-wrapper {
    background-color: $g-additional-color-15;
    flex: 1;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    img {
      display: inline-block;
      object-fit: contain;
      height: 850px;

      @include bpm-xxxl {
        height: 700px;
      }
      @include bpm-xxl {
        height: 600px;
      }
      @include bpm-xl {
        height: 500px;
      }
      @include bpm-lg {
        height: 500px;
      }
      @include bpm-mmd {
        height: 450px;
      }
      @include bpm-md {
        height: 350px;
      }
    }
    @include bpm-sm {
      display: none;
    }
  }
  &__home-page {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px 32px;
    padding-top: 1rem;

    @include bpm-sm {
      padding-top: 0;
      border-bottom: 1px solid rgba(5, 5, 5, 0.06);
      height: auto;
    }
  }
  &__laptop-icon {
    width: 500px;
    height: 350px;
    margin-bottom: 3rem;
    @include bpm-lg {
      width: 400px;
      height: 270px;
    }
    @include bpm-sm {
      width: 320px;
    }
  }
  &__home-page-logo-container {
    width: 100%;
    opacity: 0;
    padding: 0;
    height: 0px;
    pointer-events: none;

    @include bpm-sm {
      opacity: 1;
      height: 80px;
      padding-top: 1rem;
      padding-bottom: 1rem;
      justify-content: flex-start;
      display: flex;
      display: block;
    }
  }
  &__home-page-heading {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
    color: $g-additional-color-7;
    margin-bottom: 20px;

    @include bpm-md {
      font-size: 25px;
    }
  }
  &__home-page-list {
    margin-bottom: 1rem;
    padding: 0 2rem;
    font-size: 18px;
    @include bpm-md {
      padding: 0px 0px;
    }
    li {
      margin-bottom: 1rem;
    }
  }
  &__form-wrapper {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 32px;
    @include bpm-mmd {
      padding: 0px 25px;
    }
    @include bpm-sm {
      margin-bottom: 1rem;
      border-right: none;
      height: auto;
    }
  }
  &__form {
    display: flex;
    flex-direction: column;
    width: 60%;
    height: 100%;
    justify-content: center;
    font-family: $g-additional-font;
    @include bpm-mmd {
      width: 70%;
    }
    @include bpm-md {
      width: 80%;
    }
    .ant-input,
    .ant-input-password {
      border-radius: 8px;
    }
    .c-form-field__wrapper {
      margin-bottom: 1px;
    }

    .PhoneInput {
      border-width: 1px;
      border-style: solid;
      border-color: #d9d9d9;
      border-radius: 8px;
      &:hover,
      &:active,
      &:focus {
        border-color: #1fbfad;
        background-color: #ffffff;
      }
    }

    .PhoneInputCountry {
      padding: 0px 7px;
      border-right: 1px solid #d9d9d9;
      background: rgba(0, 0, 0, 0.02);
    }
    .PhoneInputInput {
      width: 100%;
      margin-bottom: 0;
      text-align: inherit;
      height: 40px;
      -webkit-appearance: none;
      background: #ffffff;
      padding: 4px 11px;
      color: rgba(0, 0, 0, 0.88);
      font-size: 14px;
      line-height: 1.5714285714285714;
      list-style: none;
      border-radius: 8px;
      border: none;
      &:hover {
        border-color: #1fbfad;
        background-color: #ffffff;
      }
    }
    @include bpm-sm {
      width: 60%;
    }
    @include bpm-xs {
      width: 100%;
    }
  }

  &__form-heading {
    font-family: $g-additional-font-1;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
    color: $g-additional-color-7;
    margin-bottom: 20px;

    @include bpm-md {
      font-size: 24px;
    }
  }
  &__footer-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    color: $g-additional-color-8;
    margin-bottom: 15px;
    align-items: center;

    @include bpm-md {
      flex-direction: column;
    }
    @include bpm-sm {
      flex-direction: row;
    }
    @include bpm-xs {
      flex-direction: column;
      font-size: 14px;
    }
  }
  &__form-btn {
    width: 100%;
    height: 44px;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    font-weight: 600;
    font-size: 16px;
  }
  &__form-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__form-btn-forgot-pass {
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    color: $g-brand-color-1;
  }

  .checkbox-top-align .ant-checkbox-wrapper {
    display: flex;
    align-items: flex-start;

    .ant-checkbox {
      align-self: flex-start;
      margin-top: 4px;
    }
  }

  .ant-otp {
    .ant-input {
      font-size: 22px;
      height: 80px;
      font-weight: 500;

      @include bpm-xs {
        height: 50px;
      }
    }
  }
}
