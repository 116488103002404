.annual-figures {
  position: relative;
  &__header {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
  }
  &__header-title {
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 15px;
    color: $g-title-color;
  }
  &__section {
    margin-bottom: 20px;
    position: relative;
  }
  &__section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
  &__section-title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5;
  }
  &__summary-tables {
    background-color: $g-additional-color-1;
    padding: 15px;
  }
  &__section-wrapper {
    background-color: $g-additional-color-1;
    padding: 20px;
    @include bpm-xs {
      padding: 0;
      background-color: transparent;
    }
  }
}

.info {
  &__section-title {
    font-size: 18px;
    font-weight: 600;
  }
  &__section-title-row {
    margin-bottom: 10px;

    &--margin {
      @include bpm-sm {
        margin-top: 30px;
      }
    }
  }
}
