@import './modals/client_modal/client_modal.style';

.client-view {
  height: 100%;
  width: 100%;
  padding: 25px;
  display: flex;
  position: relative;
  // justify-content: center;
  margin-top: 8%;
  align-items: center;
  flex-direction: column;

  @include bpm-md {
    margin-top: 15px;
  }
  @include bpm-xs {
    padding: 15px;
  }
  &__search-label {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 10px;
    @include bpm-xs {
      font-size: 20px;
    }
  }
  &__search-input {
    width: 100%;
    margin-bottom: 10px;
  }
  &__search-wrapper {
    display: flex;
    justify-content: center;
    max-width: 660px;
    width: 100%;
    // align-items: center;
  }
  &__search-results-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;

    width: 100%;
    max-width: 1000px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  &__search-btn {
    height: 42px;
    width: 150px;
    margin-left: 10px;
  }
  &__card {
    flex: 1;
    max-width: 200px;
    min-width: 180px;
    min-height: 180px;
    max-height: 230px;
    .ant-card-body {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    transition: $g-trans-base;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }

    @include bpm-xs {
      max-width: 160px;
      min-width: 160px;
      min-height: 150px;
      max-height: 230px;
    }

    p {
      @include bpm-xs {
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
        overflow: hidden;
        text-align: center;
      }
    }
  }
  &__card-name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 150px;
    margin-bottom: 10px !important;
    &--emails {
      margin: 0px !important;
      color: lighten($g-additional-color-12, 5);
    }
  }

  &__search-no-result {
    color: #ff6600;
    font-weight: 500;
    margin-bottom: 2px;
    text-align: center;
  }

  &__card-avatar {
    margin-bottom: 10px;
  }

  &__create-card {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 350px;
    padding-bottom: 20px;
  }

  &__create-header {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 20px;
  }
  //   &__create-field {
  //     width: 250px;
  //   }
  &__create-field-label {
    font-weight: 500;
  }
  &__field-wrapper {
    width: 250px;
    margin-bottom: 20px;

    &--flex-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  &__create-client-btn {
    width: 250px;
    height: 40px;
    font-size: 15px;
    margin-bottom: 10px;
  }
  &__link-view {
    text-align: center;
  }

  &__existing-clients {
    list-style: none;
    padding: 0;
    margin: 0 0 12px;
  }

  &__existing-clients-item {
    padding: 0 0 8px;
    span {
      margin-right: 10px;
    }
  }

  &__tabs {
    width: 266px;

    .ant-tabs .ant-tabs-tab {
      font-size: 16px;
      font-weight: 600;
    }
  }

  &__tabs-report-list {
    max-height: 250px;
    overflow-y: auto;
    padding: 0 8px;
  }

  &__tabs-report-list-item {
    list-style: none;
    margin-bottom: 10px;

    a {
      display: block;
      padding: 10px;
      border-radius: 7px;
      background-color: #f7f8f9;
      border: 1px solid #f7f8f9;

      svg {
        margin-right: 8px;
        color: #555;
      }

      &:hover {
        text-decoration: none;
        border: 1px solid $g-brand-color-1;
        background-color: #fafbfc;
      }
    }
  }

  .ant-input-affix-wrapper {
    padding: 0px 10px;
  }

  .ant-input-prefix {
    color: $g-brand-color-1;
    font-size: 18px;
    margin-right: 6px;
  }
}
