.l-static-content {
  width: 100%;
  height: 100%;
  position: relative;

  &__header {
    padding-left: 25px;
    width: 100%;
    border-bottom: 5px solid $g-disbaled-grey;
    display: flex;
    align-items: center;
    height: 74px;
  }

  &__logo-wrapper {
    display: flex;
  }

  &__body {
    width: 100%;
    background-color: #f5f6f7;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }

  &__copy {
    border-radius: 5px;
    width: 100%;
    max-width: 1200px;
    margin: 15px;
    padding: 40px 15px;
    background-color: $g-white;
    font-size: 14px;

    @include bp-md {
      padding: 100px 100px;
    }
  }

  &__heading {
    font-size: 18px;
    text-align: center;
    padding: 0;
    padding-bottom: 10px;

    @include bp-md {
      padding-bottom: 30px;
    }
  }

  &__sub-heading {
    font-size: 16px;
    padding: 0;
    margin-top: 40px;
    padding-bottom: 5px;
    display: flex;
  }

  &__paragraph {
    margin: 20px 0 0 !important;
  }

  &__clause {
    margin: 20px 0 0 !important;
    display: flex;
  }

  &__list {
    padding-left: 16px;
    margin-left: 22px;
    margin-top: 20px;
    padding-bottom: 10px;
  }

  &__list-item {
    padding-left: 20px;
    font-size: 14px;
    margin-top: 10px;
  }

  &__sub-list {
    padding-left: 0;
    margin-top: 10px;
    padding-bottom: 10px;
  }

  &__sub-list-item {
    padding-left: 0;
    font-size: 14px;
    list-style: none;
    margin-top: 10px;
    display: flex;

    &:before {
      content: '-';
      margin-right: 25px;
    }
  }

  &__order-list {
    list-style-type: none;
    counter-reset: list;
    padding-left: 16px;
    margin-left: 22px;
    margin-top: 20px;
    padding-bottom: 10px;
  }

  &__order-list-item {
    padding-left: 6px;
    font-size: 14px;
    margin-top: 10px;
    display: flex;
    flex-direction: row;

    &:before {
      content: '(' counter(list, lower-alpha) ')';
      counter-increment: list;
      margin-right: 20px;
    }
  }

  &__number-list {
    list-style-type: none;
    padding-left: 0;
    counter-reset: sublist;
    margin-left: 22px;
    margin-top: 20px;
    padding-bottom: 10px;
  }

  &__number-list-item {
    padding-left: 6px;
    font-size: 14px;
    margin-top: 10px;
    display: flex;
    flex-direction: row;

    &:before {
      content: counter(sublist, lower-roman) '.';
      counter-increment: sublist;
      min-width: 35px;
    }
  }

  &__number {
    min-width: 45px;
    height: 100%;
  }

  .indent {
    padding-left: 45px;
  }
}
