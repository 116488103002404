.l-filter-modal {
  &__content {
    margin-bottom: 8px;

    @include bp-lg {
      max-height: calc(100vh - 195px);
      overflow-y: auto;
    }
  }

  &__content-margin {
    margin-bottom: 8px;
  }

  &__error {
    color: #e61b00;
    width: 100%;
    font-size: 13px;
    text-align: center;
    margin-bottom: 16px;
  }

  &__close {
    cursor: pointer;
    padding: 3px;
    display: flex;
    height: 26px;
    width: 26px;
    align-items: center;
    justify-content: center;
    margin: 0 0 0 5px;
    border-radius: 50%;

    position: absolute;
    top: 62%;
    right: 44%;
    margin-right: -13px;
    margin-top: -13px;

    &:hover {
      background-color: #dbe1e4;
    }

    svg {
      width: 60%;
      height: 60%;
    }
  }

  &__footer {
    padding: 20px;
    border-top: 1px solid #ccc;
  }

  &__button-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &--mobile {
    position: relative;
  }

  &--mobile & {
    &__content {
      max-height: none;
      padding-bottom: 70px;
    }

    &__footer {
      position: fixed;
      bottom: 0;
      right: 0;
      width: 100%;
      background-color: $g-white;
    }
  }

  &__page-loader {
    border-radius: 8px;
  }
}
