.l-reset-pass {
  height: 100%;
  width: 100%;
  display: flex;

  &__logo-container {
    width: 100%;
    justify-content: flex-start;
    display: flex;
    padding-top: 15px;
  }

  &__link {
    color: $g-brand-color-1;
    font-size: 15px;
    font-weight: 400;
    padding: 0 10px;

    &:hover {
      color: $g-brand-color-1 !important;
      text-decoration: underline;
    }
  }

  &__app-name {
    // TODO: use Montserat form for this
    font-weight: 500;
    font-size: 18px;
    color: $g-additional-color-6;
    line-height: 24px;
    margin-bottom: 24px;
  }
  &__reset-form-heading {
    font-weight: 600;
    font-size: 30px;
    color: $g-additional-color-7;
    margin-bottom: 24px;

    @include bpm-md {
      font-size: 24px;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    width: 60%;
    height: 100%;
    justify-content: center;
    .ant-input,
    .ant-input-password {
      border-radius: 8px;
    }
    .c-form-field__wrapper {
      margin-bottom: 1px;
    }
    @include bpm-sm {
      width: 60%;
    }
    @include bpm-xs {
      width: 100%;
    }
  }

  &__form-btn {
    width: 100%;
    height: 44px;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    font-weight: 600;
    font-size: 16px;
    border: 1px solid $g-brand-color-1;
  }
  &__content-wrapper {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 32px;
    @include bpm-md {
      padding: 0px 25px;
    }
  }
  &__additional-link {
    display: flex;
    width: 100%;
    justify-content: center;
    font-weight: 400;
    font-size: 14px;
  }
  &__additional-link-msg {
    margin-right: 5px;
  }
  &__additional-link-trigger {
    position: relative;
    transition:
      transform 0.75s ease-out,
      -webkit-transform 0.75s ease-out;
    text-decoration: none;
    @extend %link-decoration;
    font-weight: 600;
  }

  &__form-wrapper {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  &__logo-container {
    width: 100%;
    justify-content: flex-start;
    display: flex;
    padding-top: 15px;
  }

  &__success-message-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;

    @include bp-md {
      width: 60%;
    }
  }
  &__success-message-title {
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 30px;
  }
  &__success-message {
    font-size: 16px;
    font-style: normal;
    color: $g-additional-color-8;
    margin-bottom: 25px;
  }
  &__success-message-close-btn {
    width: 100%;
    height: 40px;
  }

  .c-form-field {
    .ant-input,
    .ant-input-password {
      border-radius: 8px;
    }
  }
}
