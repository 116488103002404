.h-invite-modal {
  &__information {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 20px;
  }

  &__dual-fields-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 10px;

    .c-select .ant-select-selector {
      height: 40px !important;
    }

    .c-form-field {
      width: 42%;
    }
  }
  &__del-btn {
    margin-top: 20px;
    height: 40px;
    width: 40px !important;
  }
  &__fields-wrapper {
    margin-bottom: 10px;
  }

  &__submit-btn-wrapper {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
  &__submit-btn {
    height: 40px;
    margin-bottom: 10px;
    font-weight: 600;
  }
  &__add-user-btn {
    margin-top: 10px;
    font-weight: 600;
    margin-bottom: 20px;
  }
}
