.l-remi-cta {
  $cta-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;
  z-index: 4;
  bottom: 0;
  right: 0;
  width: 100%;
  background-color: $g-white;
  box-shadow: $cta-shadow;
  padding: 6px 8px;

  @include bp-xs {
    position: static;
    width: auto;
    bottom: auto;
    right: auto;
    padding: 0;
    box-shadow: none;
    background-color: transparent;
  }

  &__actions {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    height: 57px;
    align-items: center;
  }

  &__cta {
    border-radius: 32px;
    background-color: $g-additional-color-17;
    display: flex;
    align-items: center;
    padding: 0;
    box-shadow: none;
    width: 100%;

    @include bp-xs {
      width: auto;
      padding: 5px;
    }

    @include bp-xs {
      border: none !important;
    }

    @include bp-sm {
      margin-bottom: 0;
    }

    > span {
      color: $g-additional-color-14;
      margin: 0 15px;
      white-space: nowrap;
      height: 44px;
      align-items: center;
      justify-content: center;
      display: flex;
    }
  }

  &__cta-button {
    height: 56px;
    background-color: $g-brand-color-18 !important;
    width: 100%;
    outline: none !important;
    box-shadow: none;
    font-weight: 600;

    &:hover {
      background-color: $g-additional-color-18 !important;
    }

    @include bp-xs {
      width: auto;
      height: 44px;
    }
  }

  .fancy {
    border: none;
    box-shadow: $cta-shadow;
    width: 190px;
  }
}

.l-suburb-scoring {
  .l-remi-cta {
    &__cta {
      @include bp-xs {
        margin-bottom: 15px;
      }
    }
  }
}
