.map-section {
  position: relative;
  width: 100%;
}

.l-suburb-map {
  position: relative;
  width: 100%;
  height: 250px;
  margin: 0 auto 15px;
  z-index: 2;

  @include bp-sm {
    height: 320px;
    width: 700px;
  }

  @include bp-md {
    height: 375px;
    margin: 0 auto 30px;
  }

  &__map-wrapper {
    background: #777;
    border-radius: 6px;
    overflow: hidden;
    mask-image: -webkit-radial-gradient(white, black);
    width: 100%;
    height: 100%;
    position: relative;
  }
}

.map-home {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  color: #466799;
  background: #fffe;
  cursor: pointer;
  position: absolute;
  right: 10px;
  bottom: 10px;
  height: 35px;
  width: 35px;
  border-radius: 50%;

  &:hover {
    opacity: 0.7;
  }
  svg {
    height: 20px;
    width: 20px;
  }
}
