@import './plan_duplicate/plan_duplicate.style';

.plan-view {
  height: 100%;
  width: 100%;
  padding: 25px;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @include bpm-md {
    margin-top: 15px;
    justify-content: flex-start;
  }
  @include bpm-xs {
    padding: 15px;
  }

  &__date-field {
    width: 100%;
    input {
      height: 30px;
    }
  }

  &__modal-internal-trigger-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
