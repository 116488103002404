@import './filter/filter_field/filter_field.style';
@import './filter/filter_modal/filter_modal.style';
@import './sort/sort_modal/sort_modal.style';
@import './remi_cta_button/remi_cta_button.style.scss';

.l-suburb-scoring {
  $font-family: Inter, sans-serif;

  width: 100%;
  display: flex;
  justify-content: flex-start;
  position: relative;
  align-items: center;
  flex-direction: column;
  font-family: $font-family;
  background-color: $g-additional-color-13;

  &__no-result-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
    max-width: 800px;
    padding: 0 10px 50px;

    @include bpm-xxl {
      padding: 0 10px 100px;
    }
  }

  &__no-result-wider-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__no-result-wider-label {
    margin-right: 10px;
    font-size: 16px;
    font-weight: 500;
    font-family: $font-family;
    color: $g-text-base;
  }

  &__score {
    font-size: 20px;
    font-weight: bold;
  }

  &__main-heading {
    width: 80%;
    margin-top: 30px;

    @include bpm-xxl {
      width: 100%;
      padding: 0 10px;
    }

    @include bpm-sm {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  &__main-heading-text {
    text-align: center;
    padding: 0 15px;

    h2 {
      font-weight: normal;
    }

    h6 {
      font-weight: normal;
      font-size: 18px;
      line-height: 1.7em;
    }

    @include bpm-xxl {
      padding: 0 10px;
    }

    @include bpm-sm {
      h2 {
        font-size: 18px;
        font-weight: 600;
        padding-bottom: 5px;
        padding-top: 5px;
      }

      h6 {
        font-size: 14px;
        line-height: 1.6;
        padding-bottom: 5px;
      }
    }
  }

  &__form-wrapper {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;

    @include bpm-sm {
      margin-top: 5px;
    }

    @include bpm-xxl {
      width: 100%;
      padding: 0 10px;
    }
  }

  &__form {
    width: 100%;
  }

  &__input {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5px;
  }

  &__input-content {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;

    @include bpm-xs {
      flex-direction: column;
    }
  }

  &__selected {
    position: relative;
    flex-wrap: wrap;
    max-width: 100%;
    display: flex;
    flex: 1 1;
    max-height: 138px;
    overflow-y: auto;
    cursor: text;

    input {
      border: none;
      background-color: transparent;
      margin: 0;
      padding: 0 8px;
      outline: none;
      width: 100%;
      max-width: 100%;
      height: 50px;
      text-overflow: ellipsis;
      min-width: 254px;
      font-size: 14px;

      &[disabled] {
        opacity: 1;
        color: #ddd;
      }

      @include bp-xs {
        min-width: 350px;
      }

      @include bp-lg {
        min-width: 500px;
      }

      @include bpm-sm {
        height: 40px;
      }

      @include bpm-xs {
        font-size: 16px;
      }
    }
  }

  &__overflow-item {
    flex: none;
    align-self: center;
    max-width: 100%;
  }

  &__overflow-item-suggestion {
    border-radius: 36px;
    padding: 5px 8px 5px 15px;
    margin: 5px 10px 5px 0;
    background-color: #f2f4f7;
    display: flex;
    align-items: center;
    min-height: 39px;
    font-weight: normal;
  }

  &__overflow-item-remove {
    cursor: pointer;
    padding: 3px;
    display: flex;
    height: 22px;
    width: 22px;
    align-items: center;
    justify-content: center;
    margin: 0 0 0 5px;
    border-radius: 50%;

    &:hover {
      background-color: #dbe1e4;
    }

    svg {
      width: 60%;
      height: 60%;
    }
  }

  &__phantom-text {
    position: relative;
    overflow: hidden;
    height: 0;
    width: 0;
    opacity: 0;

    span {
      position: absolute;
      height: 0;
      top: 0;
      left: 0;
    }
  }

  &__suggestions-anchor {
    position: relative;
  }

  &__suggestions {
    background-color: $g-white;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
    padding: 10px 0;
    margin-top: 5px;
    box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
  }

  &__suggestions-list {
    overflow-y: auto;
    max-height: 135px;
    padding: 0;
    margin: 0;
  }

  &__suggestions-item {
    cursor: pointer;
    color: #6e7191;
    padding: 5px 18px;
    transition: all 0.1s ease-in-out;
    display: flex;
    align-items: center;

    &:first-child {
      margin-top: 0;
    }

    strong {
      color: #00100e;
    }

    &:hover {
      background-color: #f2f4f5;
    }
  }

  &__suggestions-empty {
    color: #6e7191;
    padding: 5px 24px;
  }

  &__button {
    height: 58px;
    line-height: 1;
    position: relative;

    > svg {
      margin-top: 3px;
    }

    > span {
      display: block;
      border-radius: 50%;
      background-color: $g-dark-color-2;
      position: absolute;
      top: 12px;
      right: 10px;
      color: $g-white;
      height: 14px;
      width: 14px;
      line-height: 14px;
      text-align: center;
      font-size: 8px;
    }

    @include bpm-sm {
      height: 38px;
    }
  }

  &__primary-button {
    height: 58px;
    margin: 0 10px;

    @include bpm-sm {
      height: 38px;
    }

    @include bp-sm {
      width: 150px;
    }
  }

  &__heading {
    text-align: center;
    padding: 0;
  }

  &__sub-heading {
    max-width: 650px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px auto;

    svg {
      margin: 0 8px;
    }
  }

  &__search-icon {
    height: 18px;
    margin-right: 18px;
  }

  &__input-field-wrapper {
    padding: 4px 18px;
    background-color: $g-white;
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
  }

  &__bullet {
    margin: 0 13px;

    &:before {
      content: '\2022';
    }
  }

  &__desc {
    font-size: 13px;
    opacity: 0.8;
    line-height: 1;
  }

  &__results-wrapper {
    width: 80%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    margin-top: 35px;
    padding: 0 10px 50px;
    position: relative;

    @include bpm-sm {
      margin-top: 5px;
    }

    @include bpm-xxl {
      width: 100%;
      padding: 0 10px 100px;
    }
  }

  &__numbers {
    margin-bottom: 15px;

    > span {
      font-weight: 500;
    }
  }

  &__pagination {
    display: flex;
    justify-content: center;
    padding: 16px 16px 16px;
    align-items: center;
    width: 100%;
    background-color: $g-light;
    border-radius: 0 0 8px 8px;

    @include bpm-sm {
      border-radius: 8px;
    }

    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link {
      border-radius: 5px;
      border: 1px solid #e9e9e9;
    }

    .ant-pagination-item {
      border-radius: 5px;
      border-color: $g-dark-color-3;
      border: 1px solid #e9e9e9;
    }

    .ant-pagination-item-active {
      font-weight: bold;
      background-color: $g-white;
      border: 1px solid #e9e9e9;
    }
  }

  &__page-loader {
    position: fixed;
  }

  &__suburb-name {
    color: #019386;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    margin: 0;

    &:hover {
      text-decoration: underline;
    }
  }

  &__badge {
    border-radius: 13px;
    padding: 1px 8px;
    display: inline-block;
    background-color: #f2f4f7;
    min-width: 40px;
    text-align: center;
    font-size: 13px;
    font-weight: 500;

    &--positive {
      color: #027a48;
      background-color: #ecfdf3;
    }

    &--negative {
      color: #b42318;
      background-color: #fef3f2;
    }

    &--margin {
      margin-top: 8px;
    }
  }

  &__column-header {
    font-weight: normal;
    font-size: 13px;
    cursor: pointer;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;

    &--bold {
      font-weight: bold;
    }

    &--normal {
      font-weight: normal;
    }

    &--default {
      cursor: default;
    }
  }

  &__percent-circle {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    position: relative;
  }

  &__percent-circle-value {
    height: 42px;
    width: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    font-weight: bold;
    font-size: 17px;
    opacity: 0.9;
    color: rgba(0, 0, 0, 0.88) !important;
  }

  &__pagination-step {
    font-family: $g-font-base;

    &:hover {
      text-decoration: none;
    }
  }

  &__button-group {
    display: flex;
    align-items: center;
    justify-content: center;

    @include bpm-xs {
      margin-top: 10px;
      width: 100%;

      .l-suburb-scoring__primary-button {
        width: 100%;
        margin: 0 10px 0 0;
      }
    }
  }

  &__clear-button {
    display: flex;
    align-items: center;
    gap: 5px;
    color: $g-text-base;
    font-size: 13px;

    &:hover {
      text-decoration: none;
      color: $g-text-base;
    }
  }

  &__sort-button {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 13px;

    svg {
      margin-top: -3px;
    }
  }

  &__wider-search {
    margin: 5px 0 20px;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    width: 80%;
    display: flex;
    padding: 0 10px;

    @include bpm-xxl {
      width: 100%;
    }
  }

  &__wider-search-label {
    font-size: 13px;
  }

  &__accordion {
    width: 100%;

    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      background-color: #fff !important;
      color: rgba(0, 0, 0, 0.88) !important;
      align-items: center;
    }
  }

  &__accordion-entry {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
    border-radius: 8px;
    margin-bottom: 16px;

    .l-suburb-scoring__column-header {
      font-size: 13px;
      font-family: $font-family;
    }
  }

  &__accordion-header {
    background-color: #f9fafb;
    border-radius: 8px 8px 0 0;
    height: 72px;
    display: flex;
    padding: 0 0 0 16px;
    align-items: center;
    gap: 10px;
    width: 100%;
  }

  &__accordion-open-report {
    margin-left: auto;
  }

  &__accordion-sub-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 8px;

    &--empty {
      padding-right: 32px;
    }
  }

  &__accordion-content {
    background-color: $g-white;
    padding: 0 16px 16px;
  }

  &__accordion-list {
    list-style-type: none;
    margin: 0;
    border: 1px solid #eaecf0;
    border-radius: 8px;
  }

  &__accordion-list-item {
    padding: 8px 16px;
    border-bottom: 1px solid #eaecf0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:last-child {
      border-bottom: none;
    }
  }

  &__accordion-column {
    display: flex;
    flex-direction: column;
    align-items: center;

    span:first-child {
      font-size: 13px;
      margin-bottom: 5px;
    }
  }

  &__sticky-pagination {
    border: 1px solid #eaecf0;
    position: sticky;
    width: 100%;
    bottom: 69px;

    @include bp-xs {
      bottom: 0;
    }
  }

  &__sticky-pagination-button {
    position: absolute;
    top: 12px;
    right: 16px;
  }

  .ant-collapse {
    border-radius: 0 0 8px 8px !important;
    overflow: hidden;
  }

  .ant-collapse-item {
    margin-bottom: 0;
    border-bottom: 1px solid #eaecf0;
  }

  .ant-collapse-content-box {
    padding: 0 !important;
  }

  .ant-table-sticky-scroll {
    display: none !important;
  }

  .ant-table-thead .ant-table-cell {
    background-color: #f9fafb;
    font-family: $font-family;
  }

  .ant-table {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
  }
}

.l-sticky-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #222;
  box-shadow:
    rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 8px;
  padding: 5px 10px 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;

  p {
    margin: 0 18px 0 0;
    padding: 0;
    color: #fff;
    text-align: right;
  }
}

.l-table-scroll-buttons {
  position: sticky;
  pointer-events: none;
  padding: 10px 10px 11px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 66px;
  z-index: 10;

  &__item {
    border-radius: 50%;
    height: 45px;
    width: 45px;
    opacity: 0.7;
    pointer-events: all;
    font-size: 18px;
  }
}
