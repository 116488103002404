.l-more-info {
  border-top: $g-dark-color-3 1px solid;
  margin-top: 30px;
  padding-top: 20px;

  h4 {
    font-size: 16px;
  }

  h5 {
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 0;
    padding-bottom: 0;
    margin-top: 3px;
  }

  p {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ul {
    margin-top: 8px;
  }

  .ant-collapse {
    box-shadow: $common-box-shadow;
    overflow: hidden;
    margin-bottom: 10px;
    border-radius: 16px;
  }

  .ant-collapse-borderless {
    background-color: #fff;
  }
}
