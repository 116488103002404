.c-automation-loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 200px;

  &__rate {
    margin-bottom: 15px;
  }

  &__message {
    font-size: 15px;
    color: #000;
    font-weight: 400;
    margin: 0 0 5px !important;
    max-width: 400px;
  }
}

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 2px solid $g-brand-color-1;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  margin-bottom: 5px;
}
.loader::after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  left: 4px;
  top: 4px;
  border: 2px solid $g-brand-color-1;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
