@import './portfolio_growth_plan/portfolio_growth_plan.style.scss';
@import './footer/footer.style.scss';
@import './book_a_call/book_a_call.style.scss';
@import './cta_button/cta_button.style.scss';
@import './learn_more/learn_more.style.scss';
@import './video_explainer/video_explainer.style.scss';

.l-lead-landing {
  margin: 0 auto;
  width: 100%;

  &__suburb-scoring {
    background-color: $g-white;
  }

  &__portfolio-plan,
  &__suburb-scoring {
    padding: 20px 20px 80px;
    margin: 0 auto;

    .ant-divider-horizontal {
      margin: 15px 0;
      border-block-start: #d0d5dd 1px solid;
    }

    .l-lead-landing__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 1200px;
      margin: 0 auto;

      &.l-lead-landing__content--reverse {
        flex-direction: column-reverse;
      }

      @include bp-sm {
        flex-direction: row;
      }

      .l-lead-landing__alpha-left,
      .l-lead-landing__alpha-right,
      .l-lead-landing__beta-left,
      .l-lead-landing__beta-right {
        flex: 1;
        padding: 10px;
      }

      .l-lead-landing__alpha-left,
      .l-lead-landing__beta-right {
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          max-width: 100%;
          height: auto;
        }
      }

      .l-lead-landing__alpha-right,
      .l-lead-landing__beta-left {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .l-lead-landing__pre-heading {
          font-size: 16px;
          font-weight: 600;
          color: #00766c;
          padding: 0;
        }

        .l-lead-landing__heading {
          font-size: 24px;
          font-weight: 700;
          color: #000;
          margin: 20px 0;
          padding: 0;
        }

        .l-lead-landing__paragraph {
          margin: 20px 0;
          font-size: 16px;
          color: #667085;
        }

        .l-lead-landing__sub-heading {
          font-size: 20px;
          font-weight: 600;
          color: #444;
          margin: 20px 0;
          padding: 0;
        }

        .l-lead-landing__list {
          list-style: none;
          padding: 0;
          margin: 20px 0;
          display: flex;
          flex-direction: column;
          gap: 10px;

          .l-lead-landing__list-item {
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            font-size: 16px;
            color: #667085;

            svg {
              width: 24px;
              height: 24px;
              margin-right: 10px;
            }
          }
        }

        .l-lead-landing__button {
          margin-top: 20px;
          padding: 10px 20px;
          background-color: #019386;
          font-weight: 600;
        }
      }
    }
  }

  @include bp-sm {
    .l-lead-landing__portfolio-plan,
    .l-lead-landing__suburb-scoring {
      padding: 40px 20px;
    }

    .l-lead-landing__content {
      max-width: 100%;
      flex-direction: row;

      &.l-lead-landing__content {
        flex-direction: row;
      }
    }
  }

  @include bp-md {
    .l-lead-landing__heading {
      font-size: 36px !important;
    }

    .l-lead-landing__paragraph,
    .l-lead-landing__list-item {
      font-size: 20px !important;
    }
  }

  @include bp-lg {
    .l-lead-landing__content {
      max-width: 1220px;
    }
  }
}
