.l-chart-wrapper {
  width: 100%;

  section {
    width: 100%;
    padding-bottom: 50%;
    position: relative;

    .misc,
    canvas {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }

    ul {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }
  }
}

.l-descriptor-trigger {
  &--graph {
    position: absolute;
    top: 2.3rem;
    right: 2.5rem;
  }
}

.l-growth {
  &__box-in {
    background-color: #eff0f6;
    border-radius: 12px;
    padding: 12px;
    color: #4e4b66;
    font-size: 14px;
    text-transform: none;
    min-height: 68px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  thead .h-display-none {
    display: none !important;
  }

  th {
    height: 40px;
    font-size: 13px !important;
    border-bottom: none !important;
    text-align: center !important;

    &:before {
      display: none;
    }

    &:first-child {
      background: white !important;
      color: gray !important;
      border-top: #cfdce5 !important;
    }
  }

  td {
    height: 40px;
    text-align: center;

    &:first-child {
      background: white !important;
      color: #4e4b66;
    }
  }
}

.l-table-descriptor {
  display: flex;
  align-items: center;
  justify-content: center;

  .l-descriptor-trigger {
    margin-left: 10px;
    margin-top: 0;
    opacity: 0.6;
  }
}
