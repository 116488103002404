@import './modals/enterprise_modal/enterprise_modal.style';
@import './enterprise_detail/enterprise_detail.style';

.l-enterprise {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;

  &__view {
    width: 80%;
    align-items: center;
    flex-direction: column;
    display: flex;

    @include bpm-xs {
      width: 100%;
      padding: 15px 5px;
    }
  }

  /* Override */
  .l-enterprise-detail {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;

    &__view {
      width: 100%;
      align-items: center;
      flex-direction: column;
      display: flex;
      padding-top: 0;

      @include bpm-xs {
        width: 100%;
        padding: 15px;
      }
    }
  }
}
