%card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  font-weight: 600;
  color: $g-additional-color-7;
}

.dashboard {
  position: relative;
  &__loader {
    align-items: flex-start;
    padding-top: 200px;
    width: 100%;
    height: 100%;
  }
  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 25px;
    @include bpm-xl {
      flex-direction: column;
    }
    @include bpm-xxl {
      flex-direction: column;
    }
  }
  &__header-title {
    font-weight: 700;
    font-size: 20px;
    color: $g-title-color;
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    margin-bottom: 10px;
  }
  &__header-fields {
    display: flex;
    justify-content: space-between;

    @include bpm-md {
      flex-wrap: wrap;
      row-gap: 15px;
    }

    @include bpm-xs {
      flex-direction: column;
      padding: 0;
      max-width: 400px;
      row-gap: 0px;
    }
  }
  &__header-field {
    display: flex;
    align-items: center;

    @include bpm-md {
      max-width: 50%;
      padding: 0px 10px;

      width: 100%;
      justify-content: space-between;
    }

    @include bpm-xs {
      max-width: 100%;
      justify-content: space-between;
      padding: 0px;
    }

    @include bp-xxl {
      margin-right: 15px;
    }

    @include bpm-xs {
      > div {
        width: 100%;
      }
      margin-top: 10px;
      .ant-input,
      .ant-select {
        width: 100% !important;
      }
    }
  }
  &__header-field-input {
    width: 130px !important;
    @include bpm-xs {
      width: 100% !important;
    }
    @include bpm-md {
      width: 100% !important;
    }
    .anticon {
      svg {
        fill: $g-dark-color-9;
      }
    }
  }
  &__header-field-label {
    margin-right: 10px;
    font-size: 14px;
    font-weight: 500;
    @include bpm-md {
      color: $g-dark-color-9;
    }
    @include bpm-sm {
      flex: 1 0 50%;
      max-width: 50%;
    }
    @include bpm-xs {
      flex: 1 0 50%;
      max-width: 50%;
    }
  }
  &__header-share {
    margin-left: 10px;
    height: 38px;
    width: 100px;
    @include bpm-xs {
      width: 100%;
      margin-left: 0;
    }
    @include bpm-md {
      width: 100%;
      margin-left: 0;
      margin-top: 10px;
    }
  }
  &__np {
    @include bpm-md {
      margin-bottom: 20px;
    }
  }

  &__card {
    box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    border: 1px solid $g-additional-color-13;
    width: 100%;
    transition: $g-trans-base;
    cursor: pointer;
    height: 100%;
    &--no-hover {
      &:hover {
        transform: none !important;
      }
    }

    &:hover {
      transform: scale(1.05);
    }
    .ant-card-body {
      padding: 20px 15px;
    }
    &--no-padding {
      .ant-card-body {
        padding: 0px;
      }
    }
    &--full-height {
      height: calc(100% - 20px);
      @include bpm-md {
        height: 100%;
      }
    }

    &--stats-card {
      border-radius: 6px;
      border: 1px solid #eaecf0;

      .ant-card-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: 100px;
        padding: 16px;
      }
      svg {
        height: 20px;
      }
    }
  }
  &__progress-card-header {
    @extend %card-header;
    font-size: 18px;

    @include bpm-sm {
      font-size: 18px;
    }
  }
  &__progress-card-values {
    margin-bottom: 5px;
    font-weight: 500;
    font-size: 14px;
    color: $g-additional-color-8;
    b {
      color: $g-additional-color-7;
      font-weight: 600;
    }
  }
  &__np-header {
    @extend %card-header;
    font-size: 18px;
    padding: 16px;
    margin-bottom: 0px;
    border-bottom: 1px solid $g-additional-color-13;
  }
  &__np-row {
    border-bottom: 1px solid $g-additional-color-13;
    font-size: 14px;
    font-weight: 500;
    color: $g-additional-color-7;
    padding: 18px 15px;
    @include bpm-lg {
      font-size: 11px;
      font-weight: 400;
    }
    @include bpm-mmd {
      font-size: 14px;
      font-weight: 500;
    }
  }
  &__np-amount-wrapper {
    font-size: 14px;
    padding: 4px 12px 4px 12px;
    text-overflow: ellipsis;
    background-color: #d6f6f4;
    color: #006464;
    border-radius: 16px;

    @include bpm-xl {
      font-size: 12px;
      padding: 0 8px;
    }
    @include bpm-lg {
      font-size: 11px;
      padding: 2px 5px;
    }

    @include bpm-md {
      padding: 4px 12px 4px 12px;
    }
    @include bpm-md {
      padding: 4px 12px 4px 12px;
      font-weight: 500;
    }
    @include bpm-sm {
      padding: 4px 12px 4px 12px;
    }
  }
  &__stats-card-num-wrapper {
    font-weight: 600;
    font-size: 21px;
    margin-bottom: 5px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: $g-additional-color-7;
  }
  &__stats-card-label-wrapper {
    font-weight: 500;
    font-size: 12px;
    color: $g-additional-color-8;
    text-align: center;
  }
  &__stats-card-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__portfolio-performance-section {
    margin-top: 20px;
  }
  &__section {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
  }
  &__header-title--medium {
    font-weight: 600;
  }
  &__client-emails-wrapper {
    display: flex;
    align-items: center;
  }
  &__client-email-actions {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  &__client-email-action {
    cursor: pointer;
    color: $g-brand-color-1;
    &--delete {
      color: $g-error;
      margin-right: 15px;
    }
  }
  &__client-invite-wrapper {
    display: flex;
    justify-content: space-between;
  }
  &__client-invite-field {
    width: 85%;
    margin-bottom: 10px;
  }
  &__client-invite-btn {
    font-size: 20px;
    cursor: pointer;
    color: $g-brand-color-1;
    margin-right: 10px;
  }

  &__chart-wrapper {
    width: 100%;
    max-width: 100%;
  }

  &__chart-content {
    width: 100% !important;
    max-width: 100% !important;
    position: relative;

    @include bpm-xs {
      height: 500px;
    }

    @include bp-sm {
      padding-bottom: 50%;

      canvas {
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
      }
    }
    @include bp-md {
      padding-bottom: 60%;
    }
    @include bp-xxl {
      padding-bottom: 50%;
    }
    &--first {
      @include bpm-xs {
        height: 550px;
      }
      @include bp-md {
        padding-bottom: 50%;
      }
    }
  }
}

.c-form-modal__dashboard-share {
  width: 450px !important;
}

.c-form-field__dashboard-internal-share {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
