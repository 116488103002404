.l-sorting {
  &__content {
    padding: 0 0 20px;
    min-height: 396px;
  }

  &__heading {
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__divider {
    margin: 15px 0;
    background-color: #e6e6e6;
    border: none;
    height: 1px;
  }

  &__footer {
    padding: 16px;
    border-top: 1px solid #ccc;
  }

  &__button-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__button {
    background: $g-brand-color-1;
    min-width: 155px;
    color: $g-white;

    height: 42px;
    border-radius: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;

    &--subtle {
      background-color: #ccc;
      color: #4e4b66;
    }
  }

  &--mobile {
    position: relative;
  }

  &--mobile & {
    &__content {
      max-height: none;
      padding-bottom: 90px;
    }

    &__footer {
      position: fixed;
      bottom: 0;
      right: 0;
      width: 100%;
      background-color: $g-white;
    }
  }

  &__control {
    .ant-radio {
      margin-right: 8px;
    }

    .ant-radio-wrapper {
      margin-top: 8px;
      color: #4e4b66;

      &:first-child {
        margin-top: 0;
      }
    }
  }
}
